
.sideBar > .ps-sidebar-container{
  background-color: #ffffff;
  padding: 30px 0;
}

.side_bar_logo_container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 30px;
}

.side_bar_settings_section {
  display: flex;
  align-items: center;
  padding: 0 20px;
  justify-content: space-around;
}

@media (max-width: 1024px) {

  .side_bar_settings_section{
    row-gap: 5px;
    flex-direction: column;
  }

}