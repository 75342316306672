.error {
  z-index: 90;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.warningIcon {
  width: 50%;
}

.error_title{
  color: red;
}
