.BeUpSoftSign {
  position: absolute;
  display: flex;
  align-items: center;
  right: 30px;
  bottom: 30px;
}
.BeUpSoftSign span {
  margin-right: 15px;
}
