.absolute_platform {
  position: absolute;
  z-index: 5;
  border-radius: 6px;
  box-shadow: 0 50px 80px #0726531a;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  overflow: hidden;
}
.absolutePlatform_closeIcon {
  position: absolute;
  width: 20px;
  top: 11px;
  right: 11px;
  z-index: 5;
}

.absolutePlatform_backdrop {
  top: 0;
  left: 0;
  z-index: 4;
  position: absolute;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.399);
}
