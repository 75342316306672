.page_footer {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.page_footer_link_container {
    display: flex;
    margin-right: 20px;
}

.page_footer_link {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-left: 10px;
}
.page_footer_link span {
  text-align: center;
}

.page_footer_link:hover {
  color: var(--beUpColor);
}

@media (max-width: 425px) {
  .page_footer_link:first-child {
   display: none;
  }
}
